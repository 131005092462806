import React from 'react'
import classNames from 'classnames'
import { graphql, navigate, StaticQuery } from 'gatsby'
import { Location, Link } from '@reach/router'

import { Classes, Divider, Icon, Menu, Text } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

const goto = path => e => {
  if (!(e.ctrlKey || e.metaKey || e.altKey)) {
    e.preventDefault()
    navigate(path)
  }
}

const Item = ({
  text,
  icon,
  children,
  path,
  location,
  active,
  intent,
  expanded = true
}) => (
  <li className={Classes.MENU_SUBMENU}>
    <Link
      className={classNames(Classes.MENU_ITEM, Classes.intentClass(intent), {
        [Classes.ACTIVE]: active || (location && location.pathname === path),
        [Classes.INTENT_PRIMARY]: active && Classes.intentClass(intent) == null
      })}
      to={path}
      // onClick={goto(path)}
    >
      <Icon icon={icon} />
      <Text className={Classes.FILL}>{text}</Text>
    </Link>
    {children && expanded && (
      <Menu style={{ paddingLeft: 10 }}>{children}</Menu>
    )}
  </li>
)

const APIGuideMenuItem = ({ location }) => (
  <StaticQuery
    query={graphql`
      {
        root: allMdx(
          sort: { fields: [frontmatter___id] }
          filter: {
            fileAbsolutePath: { regex: "/pages/docs/" }
            fields: { parentSlug: { eq: null } }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              fields {
                slug
                parentSlug
                lastPathElem
              }
            }
          }
        }
        childrenGrouped: allMdx(
          sort: { fields: [frontmatter___id] }
          filter: {
            fileAbsolutePath: { regex: "/pages/docs/" }
            fields: { parentSlug: { ne: null } }
          }
        ) {
          group(field: fields___parentSlug) {
            fieldValue
            edges {
              node {
                id
                frontmatter {
                  title
                }
                fields {
                  slug
                  parentSlug
                  lastPathElem
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      let childrenMap = {}
      data.childrenGrouped.group.forEach(group => {
        childrenMap[group.fieldValue] = group.edges
      })

      const renderRecursive = ({ node }) =>
        node && (
          <Item
            key={node.id}
            text={node.frontmatter.title}
            path={node.fields.slug}
            location={location}
            expanded={location.pathname.includes(node.fields.slug)}>
            {childrenMap[node.fields.lastPathElem] &&
              childrenMap[node.fields.lastPathElem].map(x =>
                renderRecursive({ node: x.node })
              )}
          </Item>
        )

      return (
        <Item
          icon={IconNames.MANUAL}
          text={'API Guide'}
          path={'/docs/'}
          location={location}
          expanded={location.pathname.includes('/docs/')}>
          {data.root.edges.map(renderRecursive)}
        </Item>
      )
    }}
  />
)

const Navigation = () => (
  <Location>
    {({ location }) => (
      <>
        <Menu>
          <Item
            icon={IconNames.KEY}
            text="API Tokens"
            path="/tokens/"
            location={location}
          />
          <Item
            icon={IconNames.APPLICATIONS}
            text="Applications"
            path="/"
            location={location}
          />
        </Menu>

        <Divider />

        <Menu>
          <APIGuideMenuItem location={location} />
          <Menu.Item
            icon={IconNames.BOOK}
            text="API Reference"
            target="_blank"
            href={'https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/'}
          />
        </Menu>

        <Divider />

        <Menu>
          <Item
            icon={IconNames.LAYERS}
            text="Demos"
            path="/demos/"
            location={location}
          />
        </Menu>

        <Divider />

        <Menu>
          <Item
            icon={IconNames.USER}
            text="Account"
            path={'/account/'}
            location={location}
          />
          <Item
            icon={IconNames.DOCUMENT}
            text="Billing"
            path="/billing/"
            location={location}
          />
        </Menu>

        <Divider />

        <Menu>
          <Item
            icon={IconNames.LOG_OUT}
            text="Log Out"
            path="/logout/"
            location={location}
          />
        </Menu>
      </>
    )}
  </Location>
)

export default Navigation
