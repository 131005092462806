import React from 'react'

import Layout from './base'
import Footer from '../footer'

import { Alignment, Navbar, NavbarGroup } from '@blueprintjs/core'

import logo from '../../images/bite_ai_logo.png'
import Navigation from '../navigation'
import SEO from '../seo'

const Sidebar = ({ children, style, ...rest }) => (
  <div
    {...rest}
    style={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      flex: '0',
      width: '100%',
      backgroundColor: '#fafafa',
      borderRight: '1px solid #EEE'
    }}>
    {children}
  </div>
)

const SidebarContent = ({ children }) => (
  <div
    style={{
      position: 'sticky',
      minWidth: '200px',
      width: '80%',
      maxWidth: '270px',
      top: '0',
      marginLeft: 'auto'
    }}>
    {children}
  </div>
)

const SidebarHeader = () => (
  <Navbar
    style={{
      border: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent',
      marginBottom: 30,
      marginTop: 20
    }}>
    <NavbarGroup align={Alignment.LEFT}>
      <img
        style={{ height: 40, margin: 0, marginRight: '1rem' }}
        src={logo}
        alt="Bite AI"
      />
    </NavbarGroup>
  </Navbar>
)

const Content = ({ children }) => (
  <div
    style={{
      flexDirection: 'column',
      padding: '40px 30px 10px 30px',
      minHeight: '100vh',
      display: 'flex'
    }}>
    {children}
  </div>
)

const Dashboard = ({ children, title, pageContext }) => (
  <Layout
    className={'dashboard'}
    style={{
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateColumns:
        'minmax(200px, calc(50% - 260px)) minmax(300px, 770px) 1fr'
    }}>
    <SEO
      title={title || (pageContext && pageContext.frontmatter && pageContext.frontmatter.title)}/>
    <Sidebar>
      <SidebarContent>
        <SidebarHeader />
        <Navigation />
      </SidebarContent>
    </Sidebar>
    <Content>
      <div className="dashboard-content" style={{ flex: 1 }}>
        {children}
      </div>
      <Footer />
    </Content>
    <div style={{ flexGrow: 1 }} />
  </Layout>
)

export default Dashboard
