import React from 'react'


const Footer = () => (
  <footer >
    <div>
      <ul style={{display: 'inline', listStyle: 'circle'}}>
        <li><a href="https://bite.ai/privacy/">Privacy Policy</a></li>
        <li><a href="https://bite.ai/terms/">Terms</a></li>
      </ul>
    </div>
    <div>&copy; Bite AI 2018</div>
  </footer>
)

export default Footer