import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Creating Items`}</h1>
    <p>{`Users may want to create items which aren’t already in the database. These are the steps to create an
item.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#searching-or-creating-a-brand"
        }}>{`Searching or Creating a Brand`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#creating-an-item"
        }}>{`Creating an Item`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#searching-or-creating-a-serving-unit"
        }}>{`Searching or Creating a Serving Unit`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#nutrition-fact"
        }}>{`Adding a Nutrition Fact`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#adding-an-identifier-or-barcode"
        }}>{`Adding an Identifier or a Barcode`}</a></li>
    </ol>
    <h3>{`Searching or Creating a Brand`}</h3>
    <p>{`Items have an optional brand field which can is added by either finding or creating the brand.`}</p>
    <h4>{`Searching For a Brand`}</h4>
    <p>{`To search for a brand use the `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/brands_search_list"
      }}>{`brand_search`}</a>{`
operation. An example below searches for Whole Foods. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X GET \\ “https://api-beta.bite.ai/brands/search/?query`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Whole%20Foods”`}</code></pre></div>
    <p>{`The response includes the ID which can be used when creating an item.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"count"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"next"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"previous"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"results"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"43534924-0a57-4972-8e30-32e1a8779cc1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Whole Foods"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"manufacturer"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h4>{`Creating a Brand`}</h4>
    <p>{`If a Brand cannot be found by `}<a parentName="p" {...{
        "href": "#searching-for-a-brand"
      }}>{`searching`}</a>{` then a brand must be created
using the brand_create operation.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X POST \\
  --url https://api-beta.bite.ai/brands/ \\
  --data `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"name": "Turnover Company"}'`}</span></code></pre></div>
    <p>{`The response will be a brand object with the ID which can be used to create an Item.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"b1eef362-c30d-4a93-91b7-0399aff97b4d"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Turnover Company"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"manufacturer"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Creating an Item`}</h3>
    <p>{`To create can be created using the the `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/items_create"
      }}>{`create_item`}</a>{`
operation. The example below adds an item with a brand and is private to the current user.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` 
  --request POST \\
  --url https://api-beta.bite.ai/items/ \\
  --data `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{
              "name": "Chocolate Turnover",
              "details": "extra chocolatey",
              "brand": "b1eef362-c30d-4a93-91b7-0399aff97b4d",
              "is_private": true	
           }'`}</span></code></pre></div>
    <p>{`The response will be an item object and an ID which can be used for adding nutrition facts or
ingredients.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"021264e1-2864-4aaa-8b92-f30d089ed486"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Chocolate Turnover"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"details"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"extra flavor"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"description"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"brand"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"text_ingredients"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"ingredients"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"parents"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"children"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"nutrition_facts"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"is_private"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"is_generic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"categorizations"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Searching or Creating a Serving Unit`}</h3>
    <p>{`Nutrition facts have serving units which can searched for or created.`}</p>
    <h4>{`Searching for Units`}</h4>
    <p>{`As a user is typing in the serving unit from a package a search query using the
`}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/units_search_list"
      }}>{`unit`}{`_`}{`search`}{`_`}{`list`}</a>{`
operation can be executed. Below is an example of searching for the unit ‘cup’.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X GET \\
  --url `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'https://api-beta.bite.ai/units/search/?query=cup'`}</span></code></pre></div>
    <p>{`The response has a list of units and each of them have an ID which can be used in creating the
nutrition fact.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"count"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"next"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"previous"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"results"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1f49b3a4-b335-492d-88b6-9f63f7ae0837"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"singular_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cup"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"abbreviated_singular_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cp"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"plural_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cups"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"abbreviated_plural_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cp"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"measure_type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"volume"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"measure_system"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"usc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"granularity"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"details"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h4>{`Creating a Unit`}</h4>
    <p>{`If a unit can not be found for this food item then it can be created. The example below
creates a unit for ‘Turnover’. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` --request POST \\
  --url https://api-beta.bite.ai/units/ \\
  --data `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"singular_name": "Turnover"}'`}</span></code></pre></div>
    <p>{`The response will include the unit and the ID needed when adding nutrition facts.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"44f497cc-9f6d-4352-b81d-3b4500fa6973"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"singular_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Turnover"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"abbreviated_singular_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"plural_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"null"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"abbreviated_plural_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"measure_type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"measure_system"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"granularity"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"details"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Nutrition Fact`}</h3>
    <p>{`Items optionally have nutrition facts associated with them. To add a nutrition fact use the
`}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/items_nutrition_facts_create"
      }}>{`nutrition`}{`_`}{`facts`}{`_`}{`create`}</a>{`
operation, The example below adds the serving size, and nutrients to the Chocolate Turnover.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X POST \\
  --url https://api-beta.bite.ai/items/021264e1-2864-4aaa-8b92-f30d089ed486/nutrition_facts/ \\
  --data `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{
              "serving": {
                "amount": 1.0,
                "unit_id": "44f497cc-9f6d-4352-b81d-3b4500fa6973",
                "grams": 83
              },
              "nutrition": {
                "calories": 250
              },
              "is_default": true
        }'`}</span></code></pre></div>
    <p>{`The response is below.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fd24a365-ece6-473c-bce8-742607c7aee1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"item"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"021264e1-2864-4aaa-8b92-f30d089ed486"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"serving"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
		`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"amount"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
		`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"unit"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"44f497cc-9f6d-4352-b81d-3b4500fa6973"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"singular_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Turnover"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"abbreviated_singular_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"plural_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"abbreviated_plural_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"measure_type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"measure_system"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"granularity"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"details"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
		`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"grams"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`83.0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
		`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"details"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"servings_per_package"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"nutrition"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
		`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"calories"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`250.0`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"is_default"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"identifiers"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Adding an Identifier or Barcode`}</h3>
    <p>{`Items can have identifiers like barcodes, ids from another database, or customer specific identifier.
They can be added to an item with the `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/items_ids_create"
      }}>{`item`}{`_`}{`ids`}{`_`}{`create`}</a>{`
operation.  The example below adds an ID to the Chocolate Turnover.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` --X POST \\
  --url https://api-beta.bite.ai/items/021264e1-2864-4aaa-8b92-f30d089ed486/ids/ \\
  --data `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{
              "source": "gtin-13",
              "id": "001234567890"
          }'`}</span></code></pre></div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      