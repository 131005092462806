import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import '../../styles/reset.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import 'prismjs/themes/prism.css'
import '../../styles/base.css'
import '../../styles/blueprint-changes.css'

import SEO from '../seo'

const Layout = ({ children, style, title, ...rest }) => (
  <div style={style} {...rest}>
    {title && <SEO title={title} />}
    {children}
  </div>
)

export default Layout
